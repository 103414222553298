import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles/Blog.css";

import blogBackground from "../media/background/blog.png";
import greenbackground from "../media/background/greenbackground.png";

const Blog = () => {
    const initialFetchCount = 12;
    const displayIncrement = 6;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);

    const [articles, setArticles] = useState([]);
    const [visibleCount, setVisibleCount] = useState(displayIncrement);

    useEffect(() => {
        loadArticles(0, initialFetchCount);
    }, []);

    const loadArticles = async (offset, limit) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/articles-list?limit=${limit}&offset=${offset}`);
            console.log(response);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            setArticles(prevArticles => [...prevArticles, ...data]);
        } catch (error) {
            if (process.env.NODE_ENV !== "production") {
                console.error("Error during the GET Articles List:", error);
            }
        }
    }    

    const handleShowMore = () => {
        const newVisibleCount = visibleCount + displayIncrement;
        setVisibleCount(newVisibleCount);

        if (newVisibleCount >= articles.length) {
            loadArticles(articles.length, displayIncrement);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <main className="blog">
            <img className="blog-green" src={greenbackground} alt="fond vert" />
            <div className="blog-header">
                <img src={blogBackground} className="blog-header-background" alt="Blog En-tête Background" />
                <div className="blog-header-text">
                    <h1>{!isMobile ? "Découvrez les histoires, les réflexions, l'expertise et les bonnes pratiques sur le monde de l'étiopathie" : "Actualité étiopathe"}</h1>
                    <p>Par le cabinet d'étiopathie de Chevreuse</p>
                </div>
            </div>

            <section className="blog-articles">
                {articles.slice(0, visibleCount).map(article => (
                    <div className="blog-article" key={article.id}>
                    <Link to={`/blog/${article.id}`} className="blog-article-link">
                        <div className="blog-article-image-container">
                            <img src={article.thumbnail_url} alt={article.title} loading="lazy" />
                            <div className="blog-article-date-container">
                                <p>{article.date}</p>
                            </div>
                        </div>
                    </Link>
                    <h2 dangerouslySetInnerHTML={{ __html: article.title }}></h2>
                    <div className="blog-article-bottom">
                        <Link to={`/blog/${article.id}`}>Voir article</Link>
                    </div>
                </div>  
                ))}
            </section>
            
            <div className="blog-show-more-container">
                {visibleCount < articles.length && (
                    <button  className="blog-show-more" onClick={handleShowMore}>Voir plus</button>
                )}
            </div>

        </main>
    );
}

export default Blog;