import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles/BlogSection.css";

const BlogSection = () => {
    const initialFetchCount = 3;

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        loadArticles(0, initialFetchCount);
    }, []);

    const loadArticles = async (offset, limit) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/articles-list?limit=${limit}&offset=${offset}`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            setArticles(prevArticles => [...prevArticles, ...data]);
        } catch (error) {
            if (process.env.NODE_ENV !== "production") {
                console.error("Error during the GET Articles List:", error);
            }
        }
    }    

    return (
        <main className="blog-section">
            <h2 className="blog-section-title">Vous pourriez apprécier</h2>
            <section className="blog-section-articles">
                {articles.slice(0, initialFetchCount).map(article => (
                    <div className="blog-section-article" key={article.id}>
                        <Link to={`/blog/${article.id}`} className="blog-article-link">
                            <div className="blog-article-image-container">
                                <img src={article.thumbnail_url} alt={article.title} loading="lazy" />
                                <div className="blog-article-date-container">
                                    <p>{article.date}</p>
                                </div>
                            </div>
                        </Link>
                        <h2 dangerouslySetInnerHTML={{ __html: article.title }}></h2>
                        <div className="blog-section-article-bottom">
                            <Link to={`/blog/${article.id}`}>Voir article</Link>
                        </div>
                    </div>                
                ))}
            </section>
        </main>
    );
}

export default BlogSection;