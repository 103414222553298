import React, { useState, useEffect }  from 'react';
import '../styles/Digestifs.css';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../../AuthContext";

import digestif1 from '../../media/background/digestif1.png'; 
import digestif2 from '../../media/background/digestif2.png'; 

const TroublesDigestifs = () => {
  const [digestif, setDigestif] = useState([]);
  const [editing, setEditing] = useState({ id: null });
  const [editableText, setEditableText] = useState('');
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
      fetch('/data/traitement.json')
          .then((response) => response.json())
          .then((data) => setDigestif(data.digestif))
          .catch((error) => console.error("Erreur de chargement des données:", error));
  }, [dataUpdated]);

  const handleEdit = (id, text) => {
      setEditing({ id });
      setEditableText(text);
  };

  const handleSave = async (id) => {

      const bodyContent = {
          id: id,
          text: editableText,
      };

      try {
          const response = await fetch(`${apiUrl}/updateDigestif`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyContent),
              credentials: "include"
          });

          if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
          }

          setEditing({ id: null });

          console.log("Mise à jour réussie");
          setDataUpdated(prev => !prev);
      } catch (error) {
          console.error("Erreur lors de la mise à jour de digestif :", error);
      }
  };

  return (
    <div>
        <section className="troubles-digestif-section1">
            {editing.id === 'title' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('title')}>Enregistrer</button>
                </>
            ) : (
                <h1>{digestif.title} {user && <FaEdit onClick={() => handleEdit('title', digestif.title)} />}</h1>
            )}
            {editing.id === 'content' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('content')}>Enregistrer</button>
                </>
            ) : (
                <p>{digestif.content} {user && <FaEdit onClick={() => handleEdit('content', digestif.content)} />}</p>
            )}
        </section>

        <section className="troubles-digestif-section2">
            <div className="digestif-section2-content">
                <img src={digestif1} alt="Zones touchées par les digestif" className="digestif-image" />
                <div className="digestif-section2-text">
                    {editing.id === 'title2' ? (
                        <>
                            <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="digestif-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                        </>
                    ) : (
                        <h2>{digestif.title2} {user && <FaEdit onClick={() => handleEdit('title2', digestif.title2)} />}</h2>
                    )}
                    {editing.id === 'content2' ? (
                        <>
                            <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="digestif-edit-button" onClick={() => handleSave('content2')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{digestif.content2} {user && <FaEdit onClick={() => handleEdit('content2', digestif.content2)} />}</p>
                    )}
                </div>
            </div>
        </section>

        <div className="digestif-button-container">
            <a 
                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                <button className="digestif-button">Prendre mon Rendez-vous</button>
            </a>
        </div>

        <section className="troubles-digestif-section3">
            {editing.id === 'title3' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('title3')}>Enregistrer</button>
                </>
            ) : (
                <h2>{digestif.title3} {user && <FaEdit onClick={() => handleEdit('title3', digestif.title3)} />}</h2>
            )}
            {editing.id === 'content3' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('content3')}>Enregistrer</button>
                </>
            ) : (
                <p>{digestif.content3} {user && <FaEdit onClick={() => handleEdit('content3', digestif.content3)} />}</p>
            )}
            {editing.id === 'title4' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('title4')}>Enregistrer</button>
                </>
            ) : (
                <h2>{digestif.title4} {user && <FaEdit onClick={() => handleEdit('title4', digestif.title4)} />}</h2>
            )}
            <p>
                {editing.id === 'strong1' ? (
                    <>
                        <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="digestif-edit-button" onClick={() => handleSave('strong1')}>Enregistrer</button>
                    </>
                ) : (
                    <strong>{digestif.strong1} {user && <FaEdit onClick={() => handleEdit('strong1', digestif.strong1)} />}</strong>
                )}
                {editing.id === 'content4' ? (
                    <>
                        <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="digestif-edit-button" onClick={() => handleSave('content4')}>Enregistrer</button>
                    </>
                ) : (
                    <>{digestif.content4} {user && <FaEdit onClick={() => handleEdit('content4', digestif.content4)} />}</>
                )}
            </p>
            <p>
                {editing.id === 'strong2' ? (
                    <>
                        <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="digestif-edit-button" onClick={() => handleSave('strong2')}>Enregistrer</button>
                    </>
                ) : (
                    <strong>{digestif.strong2} {user && <FaEdit onClick={() => handleEdit('strong2', digestif.strong2)} />}</strong>
                )}
                {editing.id === 'content5' ? (
                  <>
                      <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="digestif-edit-button" onClick={() => handleSave('content5')}>Enregistrer</button>
                  </>
              ) : (
                  <>{digestif.content5} {user && <FaEdit onClick={() => handleEdit('content5', digestif.content5)} />}</>
              )}
            </p>
        </section>

        <img src={digestif2} alt="douleur main" className="troubles-digestif-background" />

        <section className="troubles-digestif-section4">
            {editing.id === 'content6' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('content6')}>Enregistrer</button>
                </>
            ) : (
                <p>{digestif.content6} {user && <FaEdit onClick={() => handleEdit('content6', digestif.content6)} />}</p>
            )}
            {editing.id === 'content7' ? (
                <>
                    <textarea className="digestif-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="digestif-edit-button" onClick={() => handleSave('content7')}>Enregistrer</button>
                </>
            ) : (
                <p>{digestif.content7} {user && <FaEdit onClick={() => handleEdit('content7', digestif.content7)} />}</p>
            )}
        </section>
    </div>
  );
};

export default TroublesDigestifs;
