import React, { useState, useEffect } from 'react';
import '../styles/Sport.css';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../../AuthContext";

import sport1 from '../../media/background/sport1.png'; 
import sport2 from '../../media/background/sport2.png'; 

const EtiopathieSport = () => {
  const [sport, setSport] = useState([]);
  const [editing, setEditing] = useState({ id: null });
  const [editableText, setEditableText] = useState('');
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    fetch('/data/traitement.json')
        .then((response) => response.json())
        .then((data) => setSport(data.sport))
        .catch((error) => console.error("Erreur de chargement des données:", error));
  }, [dataUpdated]);

  const handleEdit = (id, text) => {
      setEditing({ id });
      setEditableText(text);
  };

  const handleSave = async (id) => {

      const bodyContent = {
          id: id,
          text: editableText,
      };

      try {
          const response = await fetch(`${apiUrl}/updateSport`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyContent),
              credentials: "include"
          });

          if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
          }

          setEditing({ id: null });

          console.log("Mise à jour réussie");
          setDataUpdated(prev => !prev);
      } catch (error) {
          console.error("Erreur lors de la mise à jour de sport :", error);
      }
  };

  return (
    <div>
        <section className="troubles-sport-section1">
            {editing.id === 'title' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('title')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <h1>
                    {sport.title}{' '}
                    {user && <FaEdit onClick={() => handleEdit('title', sport.title)} />}
                </h1>
            )}
            {editing.id === 'content' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content', sport.content)} />}
                </p>
            )}
        </section>

        <section className="troubles-sport-section2">
            <div className="sport-section2-content">
                <img src={sport1} alt="Zones touchées par les sport" className="sport-image" />
                <div className="sport-section2-text">
                    {editing.id === 'title2' ? (
                        <>
                            <textarea
                                className="sport-item-edit"
                                value={editableText}
                                onChange={(e) => setEditableText(e.target.value)}
                            />
                            <button className="sport-edit-button" onClick={() => handleSave('title2')}>
                                Enregistrer
                            </button>
                        </>
                    ) : (
                        <h2>
                            {sport.title2}{' '}
                            {user && <FaEdit onClick={() => handleEdit('title2', sport.title2)} />}
                        </h2>
                    )}
                    {editing.id === 'content2' ? (
                        <>
                            <textarea
                                className="sport-item-edit"
                                value={editableText}
                                onChange={(e) => setEditableText(e.target.value)}
                            />
                            <button className="sport-edit-button" onClick={() => handleSave('content2')}>
                                Enregistrer
                            </button>
                        </>
                    ) : (
                        <p>
                            {sport.content2}{' '}
                            {user && <FaEdit onClick={() => handleEdit('content2', sport.content2)} />}
                        </p>
                    )}
                </div>
            </div>
        </section>

        <div className="sport-button-container">
            <a
                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse"
                target="_blank"
                rel="noopener noreferrer"
            >
                <button className="sport-button">Prendre mon Rendez-vous</button>
            </a>
        </div>

        <section className="troubles-sport-section3">
            {editing.id === 'title3' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('title3')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <h2>
                    {sport.title3}{' '}
                    {user && <FaEdit onClick={() => handleEdit('title3', sport.title3)} />}
                </h2>
            )}
            {editing.id === 'content3' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content3')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content3}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content3', sport.content3)} />}
                </p>
            )}
            {editing.id === 'title4' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('title4')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <h2>
                    {sport.title4}{' '}
                    {user && <FaEdit onClick={() => handleEdit('title4', sport.title4)} />}
                </h2>
            )}
            {editing.id === 'content4' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content4')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content4}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content4', sport.content4)} />}
                </p>
            )}
        </section>

        <img src={sport2} alt="douleur main" className="troubles-sport-background" />

        <section className="troubles-sport-section4">
            {editing.id === 'title5' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('title5')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <h2>
                    {sport.title5}{' '}
                    {user && <FaEdit onClick={() => handleEdit('title5', sport.title5)} />}
                </h2>
            )}
            {editing.id === 'content5' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content5')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content5}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content5', sport.content5)} />}
                </p>
            )}
            {editing.id === 'content6' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content6')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content6}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content6', sport.content6)} />}
                </p>
            )}
            {editing.id === 'title6' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('title6')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <h2>
                    {sport.title6}{' '}
                    {user && <FaEdit onClick={() => handleEdit('title6', sport.title6)} />}
                </h2>
            )}
            {editing.id === 'content7' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content7')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content7}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content7', sport.content7)} />}
                </p>
            )}
            {editing.id === 'content8' ? (
                <>
                    <textarea
                        className="sport-item-edit"
                        value={editableText}
                        onChange={(e) => setEditableText(e.target.value)}
                    />
                    <button className="sport-edit-button" onClick={() => handleSave('content8')}>
                        Enregistrer
                    </button>
                </>
            ) : (
                <p>
                    {sport.content8}{' '}
                    {user && <FaEdit onClick={() => handleEdit('content8', sport.content8)} />}
                </p>
            )}
        </section>
    </div>
  );

};

export default EtiopathieSport;
