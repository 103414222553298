import React, { useState, useEffect } from 'react';
import '../styles/Orl.css';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../../AuthContext";

import musculo1 from '../../media/background/orl1.png'; 
import musculo2 from '../../media/background/orl2.png'; 

const TroublesORL = () => {
  const [orl, setOrl] = useState([]);
  const [editing, setEditing] = useState({ id: null });
  const [editableText, setEditableText] = useState('');
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    fetch('/data/traitement.json')
        .then((response) => response.json())
        .then((data) => setOrl(data.orl))
        .catch((error) => console.error("Erreur de chargement des données:", error));
  }, [dataUpdated]);

  const handleEdit = (id, text) => {
      setEditing({ id });
      setEditableText(text);
  };

  const handleSave = async (id) => {

      const bodyContent = {
          id: id,
          text: editableText,
      };

      try {
          const response = await fetch(`${apiUrl}/updateOrl`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyContent),
              credentials: "include"
          });

          if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
          }

          setEditing({ id: null });

          console.log("Mise à jour réussie");
          setDataUpdated(prev => !prev);
      } catch (error) {
          console.error("Erreur lors de la mise à jour de orl :", error);
      }
  };

  return (
    <div>
        <section className="troubles-orl-section1">
            {editing.id === 'title' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('title')}>Enregistrer</button>
                </>
            ) : (
                <h1>{orl.title} {user && <FaEdit onClick={() => handleEdit('title', orl.title)} />}</h1>
            )}
            {editing.id === 'content' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('content')}>Enregistrer</button>
                </>
            ) : (
                <p>{orl.content} {user && <FaEdit onClick={() => handleEdit('content', orl.content)} />}</p>
            )}
        </section>

        <section className="troubles-orl-section2">
          <div className="orl-section2-content">
            <img src={musculo1} alt="Zones touchées par les orl" className="orl-image" />
            <div className="orl-section2-text">
              {editing.id === 'title2' ? (
                  <>
                      <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="orl-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                  </>
              ) : (
                  <h2>{orl.title2} {user && <FaEdit onClick={() => handleEdit('title2', orl.title2)} />}</h2>
              )}
              {editing.id === 'content2' ? (
                  <>
                      <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="orl-edit-button" onClick={() => handleSave('content2')}>Enregistrer</button>
                  </>
              ) : (
                  <p>{orl.content2} {user && <FaEdit onClick={() => handleEdit('content2', orl.content2)} />}</p>
              )}
            </div>
          </div>
      </section>

      <div className="orl-button-container">
        <a 
              href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
              target="_blank" 
              rel="noopener noreferrer"
          >
              <button className="orl-button">Prendre mon Rendez-vous</button>
          </a>
      </div>

      <section className="troubles-orl-section3">
          {editing.id === 'title3' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('title3')}>Enregistrer</button>
              </>
          ) : (
              <h2>{orl.title3} {user && <FaEdit onClick={() => handleEdit('title3', orl.title3)} />}</h2>
          )}
          {editing.id === 'content3' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('content3')}>Enregistrer</button>
              </>
          ) : (
              <p>{orl.content3} {user && <FaEdit onClick={() => handleEdit('content3', orl.content3)} />}</p>
          )}
          {editing.id === 'content4' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('content4')}>Enregistrer</button>
              </>
          ) : (
              <p>{orl.content4} {user && <FaEdit onClick={() => handleEdit('content4', orl.content4)} />}</p>
          )}
          {editing.id === 'title4' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('title4')}>Enregistrer</button>
              </>
          ) : (
              <h2>{orl.title4} {user && <FaEdit onClick={() => handleEdit('title4', orl.title4)} />}</h2>
          )}
          {editing.id === 'content5' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('content5')}>Enregistrer</button>
              </>
          ) : (
              <p>{orl.content5} {user && <FaEdit onClick={() => handleEdit('content5', orl.content5)} />}</p>
          )}
          <ul>
            {editing.id === 'li1' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('li1')}>Enregistrer</button>
                </>
            ) : (
                <li>{orl.li1} {user && <FaEdit onClick={() => handleEdit('li1', orl.li1)} />}</li>
            )}
            {editing.id === 'li2' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('li2')}>Enregistrer</button>
                </>
            ) : (
                <li>{orl.li2} {user && <FaEdit onClick={() => handleEdit('li2', orl.li2)} />}</li>
            )}
          </ul>
      </section>

      <img src={musculo2} alt="douleur main" className="troubles-orl-background"/>

      <section className="troubles-orl-section4">
          {editing.id === 'title5' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('title5')}>Enregistrer</button>
              </>
          ) : (
              <h2>{orl.title5} {user && <FaEdit onClick={() => handleEdit('title5', orl.title5)} />}</h2>
          )}
          <ul>
            {editing.id === 'li3' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('li3')}>Enregistrer</button>
                </>
            ) : (
                <li>{orl.li3} {user && <FaEdit onClick={() => handleEdit('li3', orl.li3)} />}</li>
            )}
            {editing.id === 'li4' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('li4')}>Enregistrer</button>
                </>
            ) : (
                <li>{orl.li4} {user && <FaEdit onClick={() => handleEdit('li4', orl.li4)} />}</li>
            )}
            {editing.id === 'li5' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('li5')}>Enregistrer</button>
                </>
            ) : (
                <li>{orl.li5} {user && <FaEdit onClick={() => handleEdit('li5', orl.li5)} />}</li>
            )}
            {editing.id === 'li6' ? (
                <>
                    <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="orl-edit-button" onClick={() => handleSave('li6')}>Enregistrer</button>
                </>
            ) : (
                <li>{orl.li6} {user && <FaEdit onClick={() => handleEdit('li6', orl.li6)} />}</li>
            )}
          </ul>
          {editing.id === 'title6' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('title6')}>Enregistrer</button>
              </>
          ) : (
              <h2>{orl.title6} {user && <FaEdit onClick={() => handleEdit('title6', orl.title6)} />}</h2>
          )}
          {editing.id === 'content6' ? (
              <>
                  <textarea className="orl-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="orl-edit-button" onClick={() => handleSave('content6')}>Enregistrer</button>
              </>
          ) : (
              <p>{orl.content6} {user && <FaEdit onClick={() => handleEdit('content6', orl.content6)} />}</p>
          )}
      </section>
    </div>
  );
};

export default TroublesORL;
