import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/Cabinet.css';
import Modal from 'react-modal';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../AuthContext";

import image1 from '../media/background/cabinet1.png';
import image2 from '../media/background/cabinet2.png';
import image3 from '../media/background/cabinet3.png';
import member1 from '../media/background/member1.png';
import member2 from '../media/background/member2.png';
import member3 from '../media/background/member3.png';
import greenbackground from "../media/background/greenbackground.png";

const images = [image1, image2, image3];

const Cabinet = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [cabinet, setCabinet] = useState([]);
    const [editing, setEditing] = useState({ id: null });
    const [editableText, setEditableText] = useState('');
    const { user } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [dataUpdated, setDataUpdated] = useState(false);

    const openModal = (image) => {
        setCurrentImage(image);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setCurrentImage(null);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const customStyles = {
        content: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            padding: 0,
            border: 'none',
            background: 'none',
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 1000,
        }
    };

    useEffect(() => {
        fetch('/data/cabinet.json')
            .then((response) => response.json())
            .then((data) => setCabinet(data.cabinet))
            .catch((error) => console.error("Erreur de chargement des données:", error));
    }, [dataUpdated]);

    const handleEdit = (id, text) => {
        setEditing({ id });
        setEditableText(text);
    };

    const handleSave = async (id) => {
    
        const bodyContent = {
            id: id,
            text: editableText,
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateCabinet`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ id: null });

            console.log("Mise à jour réussie");
            setDataUpdated(prev => !prev);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de cabinet :", error);
        }
    };

    return (
        <div className="cabinet">
            <img className="cabinet-green" src={greenbackground} alt="fond vert" />
            <section className="cabinet-section1">
                {editing.id === 'title' ? (
                    <>
                        <textarea className="cabinet-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="cabinet-edit-button" onClick={() => handleSave('title')}>Enregistrer</button>
                    </>
                ) : (
                    <h2>{cabinet.title} {user && <FaEdit onClick={() => handleEdit('title', cabinet.title)} />}</h2>
                )}
                <div className="cabinet-slide-container">
                    <Slider {...settings}>
                    {images.map((image, index) => (
                        <div className="slide" key={index} onClick={() => openModal(image)}>
                        <img src={image} alt={`cabinet-${index}`} className="slide-image" />
                        </div>
                    ))}
                    </Slider>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Image Modal"
                >
                    <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '20px', background: 'transparent', border: 'none', color: 'white', fontSize: '2em', zIndex: 1001 }}>×</button>
                    {currentImage && <img src={currentImage} alt="Full size" className="full-size-image" />}
                </Modal>
            </section>

            <section className="cabinet-section2">
                {editing.id === 'title2' ? (
                    <>
                        <textarea className="cabinet-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="cabinet-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                    </>
                ) : (
                    <h2>{cabinet.title2} {user && <FaEdit onClick={() => handleEdit('title2', cabinet.title2)} />}</h2>
                )}
                {editing.id === 'content1' ? (
                    <>
                        <textarea className="cabinet-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="cabinet-edit-button" onClick={() => handleSave('content1')}>Enregistrer</button>
                    </>
                ) : (
                    <p className="cabinet-section2-intro">{cabinet.content1} {user && <FaEdit onClick={() => handleEdit('content1', cabinet.content1)} />}</p>
                )}
                <div className="cabinet-section2-section">
                    <div className="cabinet-section2-member">
                        <img src={member1} alt="Bénédicte Herrmann" className="cabinet-section2-image" />
                        <div className="cabinet-section2-info">
                            <h2>Bénédicte Herrmann-Contamin</h2>
                            {editing.id === 'content2' ? (
                                <>
                                    <textarea className="cabinet-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                    <button className="cabinet-edit-button" onClick={() => handleSave('content2')}>Enregistrer</button>
                                </>
                            ) : (
                                <p>{cabinet.content2} {user && <FaEdit onClick={() => handleEdit('content2', cabinet.content2)} />}</p>
                            )}
                            <a 
                                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <button className="cabinet-section2-appointment-button">Prendre mon Rendez-vous</button>
                            </a>
                        </div>
                    </div>
                    <div className="cabinet-section2-member2">
                        <img src={member2} alt="Clélia Hamaissi" className="cabinet-section2-image" />
                        <div className="cabinet-section2-info">
                            <h2>Clélia Hamaissi</h2>
                            {editing.id === 'content3' ? (
                                <>
                                    <textarea className="cabinet-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                    <button className="cabinet-edit-button" onClick={() => handleSave('content3')}>Enregistrer</button>
                                </>
                            ) : (
                                <p>{cabinet.content3} {user && <FaEdit onClick={() => handleEdit('content3', cabinet.content3)} />}</p>
                            )}
                            <a 
                                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <button className="cabinet-section2-appointment-button">Prendre mon Rendez-vous</button>
                            </a>
                        </div>
                    </div>
                    <div className="cabinet-section2-member">
                        <img src={member3} alt="Théo BECCARIA-RAYNAL" className="cabinet-section2-image" />
                        <div className="cabinet-section2-info">
                            <h2>Théo BECCARIA-RAYNAL</h2>
                            {editing.id === 'content4' ? (
                                <>
                                    <textarea className="cabinet-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                    <button className="cabinet-edit-button" onClick={() => handleSave('content4')}>Enregistrer</button>
                                </>
                            ) : (
                                <p>{cabinet.content4} {user && <FaEdit onClick={() => handleEdit('content4', cabinet.content4)} />}</p>
                            )}
                            <a 
                                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <button className="cabinet-section2-appointment-button">Prendre mon Rendez-vous</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Cabinet;
