import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../AuthContext";

const LogOut = () => {
    const { LogOut } = useAuth();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const logOutUser = async () => {
            try {
                await fetch(`${apiUrl}/logOut`, {
                    method: 'POST',
                    credentials: 'include'
                });

                LogOut();

                navigate('/');
            } catch (error) {
                console.error("Erreur lors de la déconnexion :", error);
            }
        };

        logOutUser();
    }, [LogOut, navigate, apiUrl]);

    return (
        <div>
            <p>Déconnexion...</p>
        </div>
    );
};

export default LogOut;
