import React from 'react';
import { Link } from "react-router-dom";

import "./styles/Footer.css";

function Footer() {
    return (
      <footer className="footer-container">
        <div className="footer-content">
          <div className="footer-brand">
            <h2>Cabinet d'étiopathie de Chevreuse</h2>
          </div>
          <div className="footer-sections">
            <div className="footer-section">
              <h3>Pages</h3>
              <ul>
                <li><Link to="/" className="footer-link">Accueil</Link></li>
                <li><Link to="/traitement" className="footer-link">Traitement</Link></li>
                <li><Link to="/faq" className="footer-link">FAQ</Link></li>
                <li><Link to="/reviews" className="footer-link">Témoignages</Link></li>
                <li><Link to="/blog" className="footer-link">Blog</Link></li>
              </ul>
            </div>
            <div className="footer-section contact">
              <h3>Contact</h3>
              <p>28 Rue de la Porte de Paris, 78460 Chevreuse</p>
              <p>0130473290</p>
            </div>
            <div className="footer-section">
              <h3>Légal</h3>
              <ul>
                <li>Politique de confidentialité</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;