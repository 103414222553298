import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/ReviewsSection.css';
import { NextArrow, PrevArrow } from './Arrows';

function ReviewsSection() {
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/reviews');
  };

  useEffect(() => {
    fetch('/data/reviews.json')
      .then(response => response.json())
      .then(data => setReviews(data));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "20px"
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px"
        }
      }
    ]
  };

  return (
    <section className="review-section">
      <h2>Les derniers avis</h2>
      <div className="review-slider-container">
        <Slider {...settings}>
            {reviews.map((review, index) => (
            <div key={index} className="review-card-wrapper">
                <div className="review-card">
                  <div className="review-card-header">
                      <div className="review-card-initial">{review.initial}</div>
                      <div className="review-card-info">
                        <h3>{review.name}</h3>
                        <div className="review-card-rating">
                            {Array(review.rating).fill().map((_, i) => (
                            <span key={i}>⭐</span>
                            ))}
                        </div>
                      </div>
                  </div>
                <p className="review-card-text">{review.review}</p>
                <button className="review-card-link" onClick={handleClick}>Lire plus</button>
                </div>
            </div>
            ))}
        </Slider>
        </div>
    </section>
  );
}

export default ReviewsSection;
