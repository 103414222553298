import React, { useState, useEffect } from 'react';
import '../styles/Femme.css';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../../AuthContext";

import femme1 from '../../media/background/femme1.png'; 
import femme2 from '../../media/background/femme2.png'; 

const SanteFemme = () => {
  const [femme, setFemme] = useState([]);
  const [editing, setEditing] = useState({ id: null });
  const [editableText, setEditableText] = useState('');
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    fetch('/data/traitement.json')
        .then((response) => response.json())
        .then((data) => setFemme(data.femme))
        .catch((error) => console.error("Erreur de chargement des données:", error));
  }, [dataUpdated]);

  const handleEdit = (id, text) => {
      setEditing({ id });
      setEditableText(text);
  };

  const handleSave = async (id) => {

      const bodyContent = {
          id: id,
          text: editableText,
      };

      try {
          const response = await fetch(`${apiUrl}/updateFemme`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyContent),
              credentials: "include"
          });

          if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
          }

          setEditing({ id: null });

          console.log("Mise à jour réussie");
          setDataUpdated(prev => !prev);
      } catch (error) {
          console.error("Erreur lors de la mise à jour de femme :", error);
      }
  };

  return (
    <div>
        <section className="troubles-femme-section1">
            {editing.id === 'title' ? (
                <>
                    <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="femme-edit-button" onClick={() => handleSave('title')}>Enregistrer</button>
                </>
            ) : (
                <h1>{femme.title} {user && <FaEdit onClick={() => handleEdit('title', femme.title)} />}</h1>
            )}
            {editing.id === 'content' ? (
                <>
                    <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="femme-edit-button" onClick={() => handleSave('content')}>Enregistrer</button>
                </>
            ) : (
                <p>{femme.content} {user && <FaEdit onClick={() => handleEdit('content', femme.content)} />}</p>
            )}
        </section>

        <section className="troubles-femme-section2">
            <div className="femme-section2-content">
                <img src={femme1} alt="Zones touchées par les femme" className="femme-image" />
                <div className="femme-section2-text">
                    {editing.id === 'title2' ? (
                        <>
                            <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="femme-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                        </>
                    ) : (
                        <h2>{femme.title2} {user && <FaEdit onClick={() => handleEdit('title2', femme.title2)} />}</h2>
                    )}
                    {editing.id === 'content2' ? (
                        <>
                            <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="femme-edit-button" onClick={() => handleSave('content2')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{femme.content2} {user && <FaEdit onClick={() => handleEdit('content2', femme.content2)} />}</p>
                    )}
                </div>
            </div>
        </section>

        <div className="femme-button-container">
            <a 
                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                <button className="femme-button">Prendre mon Rendez-vous</button>
            </a>
        </div>

        <section className="troubles-femme-section3">
            {editing.id === 'title3' ? (
                <>
                    <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="femme-edit-button" onClick={() => handleSave('title3')}>Enregistrer</button>
                </>
            ) : (
                <h2>{femme.title3} {user && <FaEdit onClick={() => handleEdit('title3', femme.title3)} />}</h2>
            )}
            <p>
              {editing.id === 'strong1' ? (
                  <>
                      <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="femme-edit-button" onClick={() => handleSave('strong1')}>Enregistrer</button>
                  </>
              ) : (
                  <strong>{femme.strong1} {user && <FaEdit onClick={() => handleEdit('strong1', femme.strong1)} />}</strong>
              )}
              {editing.id === 'content3' ? (
                  <>
                      <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="femme-edit-button" onClick={() => handleSave('content3')}>Enregistrer</button>
                  </>
              ) : (
                  <>{femme.content3} {user && <FaEdit onClick={() => handleEdit('content3', femme.content3)} />}</>
              )}
            </p>
            <p>
              {editing.id === 'strong2' ? (
                  <>
                      <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="femme-edit-button" onClick={() => handleSave('strong2')}>Enregistrer</button>
                  </>
              ) : (
                  <strong>{femme.strong2} {user && <FaEdit onClick={() => handleEdit('strong2', femme.strong2)} />}</strong>
              )}
              {editing.id === 'content4' ? (
                  <>
                      <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="femme-edit-button" onClick={() => handleSave('content4')}>Enregistrer</button>
                  </>
              ) : (
                  <>{femme.content4} {user && <FaEdit onClick={() => handleEdit('content4', femme.content4)} />}</>
              )}
            </p>
            <p>
              {editing.id === 'strong3' ? (
                  <>
                      <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="femme-edit-button" onClick={() => handleSave('strong3')}>Enregistrer</button>
                  </>
              ) : (
                  <strong>{femme.strong3} {user && <FaEdit onClick={() => handleEdit('strong3', femme.strong3)} />}</strong>
              )}
              {editing.id === 'content5' ? (
                  <>
                      <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="femme-edit-button" onClick={() => handleSave('content5')}>Enregistrer</button>
                  </>
              ) : (
                  <>{femme.content5} {user && <FaEdit onClick={() => handleEdit('content5', femme.content5)} />}</>
              )}
            </p>
        </section>

        <img src={femme2} alt="douleur main" className="troubles-femme-background" />

        <section className="troubles-femme-section4">
            {editing.id === 'content6' ? (
                <>
                    <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="femme-edit-button" onClick={() => handleSave('content6')}>Enregistrer</button>
                </>
            ) : (
                <p>{femme.content6} {user && <FaEdit onClick={() => handleEdit('content6', femme.content6)} />}</p>
            )}
            {editing.id === 'content7' ? (
                <>
                    <textarea className="femme-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="femme-edit-button" onClick={() => handleSave('content7')}>Enregistrer</button>
                </>
            ) : (
                <p>{femme.content7} {user && <FaEdit onClick={() => handleEdit('content7', femme.content7)} />}</p>
            )}
        </section>
    </div>
  );
};

export default SanteFemme;
