import React, { useState, useEffect } from 'react';
import './styles/Traitement.css';
import traitementImage from '../media/background/traitement.png';
import TroublesMusculosquelettique from './traitement-components/Musculo';
import TroublesORL from './traitement-components/Orl';
import EtiopathieSport from './traitement-components/Sport';
import TroublesDigestifs from './traitement-components/Digestifs';
import SanteFemme from './traitement-components/Femme';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../AuthContext";

import greenbackground from "../media/background/greenbackground.png";

const Traitement = ({ initialSection = 'Troubles Musculosquelettique' }) => {
  const [activeSection, setActiveSection] = useState(initialSection);
  const [traitement, setTraitement] = useState([]);
  const [editing, setEditing] = useState({ id: null });
  const [editableText, setEditableText] = useState('');
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    setActiveSection(initialSection);
  }, [initialSection]);

  const renderSection = () => {
    switch (activeSection) {
      case 'Troubles Musculosquelettique':
        return <TroublesMusculosquelettique />;
      case 'Troubles ORL':
        return <TroublesORL />;
      case 'Etiopathie du sport':
        return <EtiopathieSport />;
      case 'Troubles digestifs':
        return <TroublesDigestifs />;
      case 'Sante de la femme':
        return <SanteFemme />;
      default:
        return <TroublesMusculosquelettique />;
    }
  };

  const sections = [
    { id: 'Troubles Musculosquelettique', display: 'Troubles\nMusculosquelettique' },
    { id: 'Troubles ORL', display: 'Troubles\nORL' },
    { id: 'Etiopathie du sport', display: 'Etiopathie\ndu sport' },
    { id: 'Troubles digestifs', display: 'Troubles\ndigestifs' },
    { id: 'Sante de la femme', display: 'Sante\nde la femme' }
  ];

  useEffect(() => {
      fetch('/data/traitement.json')
          .then((response) => response.json())
          .then((data) => setTraitement(data.traitement))
          .catch((error) => console.error("Erreur de chargement des données:", error));
  }, [dataUpdated]);

  const handleEdit = (id, text) => {
      setEditing({ id });
      setEditableText(text);
  };

  const handleSave = async (id) => {

      const bodyContent = {
          id: id,
          text: editableText,
      };

      try {
          const response = await fetch(`${apiUrl}/updateTraitement`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyContent),
              credentials: "include"
          });

          if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
          }

          setEditing({ id: null });

          console.log("Mise à jour réussie");
          setDataUpdated(prev => !prev);
      } catch (error) {
          console.error("Erreur lors de la mise à jour de traitement :", error);
      }
  };

  return (
    <main className="traitement">
        <img className="traitement-green" src={greenbackground} alt="fond vert" />
        <section className="traitement-section">
            <div className="traitement-content">
                <h2 className="traitement-title">
                    {editing.id === 'title1' ? (
                        <>
                            <textarea className="traitement-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="traitement-edit-button" onClick={() => handleSave('title1')}>Enregistrer</button>
                        </>
                    ) : (
                        <span className="title-line line-1">{traitement.title1} {user && <FaEdit onClick={() => handleEdit('title1', traitement.title1)} />}</span>
                    )}
                    {editing.id === 'title2' ? (
                        <>
                            <textarea className="traitement-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="traitement-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                        </>
                    ) : (
                        <span className="title-line line-2">{traitement.title2} {user && <FaEdit onClick={() => handleEdit('title2', traitement.title2)} />}</span>
                    )}
                </h2>
                {editing.id === 'content' ? (
                    <>
                        <textarea className="traitement-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                        <button className="traitement-edit-button" onClick={() => handleSave('content')}>Enregistrer</button>
                    </>
                ) : (
                    <p>{traitement.content} {user && <FaEdit onClick={() => handleEdit('content', traitement.content)} />}</p>
                )}
            </div>
            <div className="traitement-image-container">
                <img src={traitementImage} alt="Traitement étiopathique" className="traitement-image" />
                <a 
                  href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
                  target="_blank" 
                  rel="noopener noreferrer"
              >
                  <button className="traitement-button">Prendre mon Rendez-vous</button>
              </a>
            </div>
        </section>
        <nav className="traitement-nav">
            {sections.map(section => (
            <span
                key={section.id}
                className={`traitement-nav-item ${activeSection === section.id ? 'active' : ''}`}
                onClick={() => setActiveSection(section.id)}
            >
                {section.display.split('\n').map((line, index) => (
                <span key={index} className="nav-line">{line}</span>
                ))}
            </span>
            ))}
        </nav>
        <section className="traitement-subsection">
            {renderSection()}
        </section>
    </main>
  );
};

export default Traitement;
