import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { NavbarProvider } from "./NavbarContext";
import { useAuth } from "./AuthContext";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import FAQ from "./components/FAQ";
import Reviews from "./components/Reviews";
import Blog from "./components/Blog";
import Cabinet from "./components/Cabinet";
import Traitement from "./components/Traitement";
import ArticleGenericPage from "./components/ArticleGenericPage";
import AdminComponent from "./components/Admin";
import LogOutComponent from "./components/LogOut";

import ScrollToTop from './ScrollToTop';

import ReactGA from "react-ga4";

function App() {
  const { LogIn, LogOut, setIsLoading } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  React.useEffect(() => {
    ReactGA.initialize([
    {
        trackingId: "G-2EB3JL7X42"
      },  
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const verifySession = async () => {
        try {
            //verification session
            const response = await fetch(`${apiUrl}/v`, {
                credentials: "include"
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Session non valide ou expirée");
            }
            
            const userId = data.userId;
            LogIn(userId);
            
        } catch (error) {
            LogOut();
        } finally {
            setIsLoading(false);
        }
    };

    verifySession();
  }, [LogIn, LogOut, apiUrl, setIsLoading]);

  return (
    <NavbarProvider>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:articleId" element={<ArticleGenericPage />} />
          <Route path="/cabinet" element={<Cabinet />} />
          <Route path="/traitement" element={<Traitement initialSection="Troubles Musculosquelettique" />} />
          <Route path="/traitement1" element={<Traitement initialSection="Troubles Musculosquelettique" />} />
          <Route path="/traitement2" element={<Traitement initialSection="Troubles ORL" />} />
          <Route path="/traitement3" element={<Traitement initialSection="Etiopathie du sport" />} />
          <Route path="/traitement4" element={<Traitement initialSection="Troubles digestifs" />} />
          <Route path="/traitement5" element={<Traitement initialSection="Sante de la femme" />} />
          <Route path="/admin" element={<AdminComponent />} />
          <Route path="/logout" element={<LogOutComponent />} />
        </Routes>
        <Footer />
      </Router>
    </NavbarProvider>
  );
}

export default App;