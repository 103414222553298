import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../AuthContext";

import "./styles/Home.css";

import ReviewsSection from "./ReviewsSection";
import BlogSection from "./BlogSection";

import vector from "../media/background/vector.png";
import herrmann from "../media/background/herrmann.png";
import clelia from "../media/background/clelia.png";
import theo from "../media/background/theo.png";
import pourquoi from "../media/background/pourquoi.png";
import pourquoiNous from "../media/background/pourquoi_nous.png";
import contreIndication from "../media/background/contre_indication.png";
import greenbackground from "../media/background/greenbackground.png";

const Home = () => {
    const images = [herrmann, clelia, theo];
    const names = ["Bénédicte Herrmann-Contamin", "Clélia HAMAISSI", "Théo BECCARIA-RAYNAL"];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [home, setHome] = useState([]);
    const [editing, setEditing] = useState({ id: null });
    const [editableText, setEditableText] = useState('');
    const { user } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [dataUpdated, setDataUpdated] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 10000);

        return () => clearInterval(interval);
    }, [images.length]);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    useEffect(() => {
        fetch('/data/home.json')
            .then((response) => response.json())
            .then((data) => setHome(data.home))
            .catch((error) => console.error("Erreur de chargement des données:", error));
    }, [dataUpdated]);

    const handleEdit = (id, text) => {
        setEditing({ id });
        setEditableText(text);
    };

    const handleSave = async (id) => {
    
        const bodyContent = {
            id: id,
            text: editableText,
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateHome`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ id: null });

            console.log("Mise à jour réussie");
            setDataUpdated(prev => !prev);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de home :", error);
        }
    };

    return (
        <main className="home">
            <img className="home-green" src={greenbackground} alt="fond vert" />
            <section className="home-section1">
                <div className="home-section1-container">
                    <div className="home-section1-text-content">
                        {editing.id === 'title' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('title')}>Enregistrer</button>
                            </>
                        ) : (
                            <h1>{home.title} {user && <FaEdit onClick={() => handleEdit('title', home.title)} />}</h1>
                        )}
                        {editing.id === 'content1' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('content1')}>Enregistrer</button>
                            </>
                        ) : (
                            <p>{home.content1} {user && <FaEdit onClick={() => handleEdit('content1', home.content1)} />}</p>
                        )}
                        <div className="home-section1-buttons">
                            <a
                                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="home-section1-btn">Prendre mon Rendez-vous</button>
                            </a>
                        </div>
                    </div>
                    <div className="home-section1-image-content">
                        <img className="home-section1-vector" src={vector} alt="arrière plan" />
                        <TransitionGroup>
                            <CSSTransition
                                key={currentImageIndex}
                                timeout={1000}
                                classNames="fade"
                            >
                                <img className="home-section1-vector2" src={images[currentImageIndex]} alt="arrière plan" />
                            </CSSTransition>
                        </TransitionGroup>
                        <div className="home-section1-info-card">
                            <p>{names[currentImageIndex]}</p>
                            <a
                                href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="home-section1-btn-card">Prendre un rendez-vous</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
    
            <section className="home-section2">
                <div className="home-section2-container">
                    <div className="home-section2-image-content">
                        <img src={pourquoi} alt="Pourquoi l'étiopathie ?" />
                    </div>
                    <div className="home-section2-text-content">
                        {editing.id === 'title2' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                            </>
                        ) : (
                            <h2>{home.title2} {user && <FaEdit onClick={() => handleEdit('title2', home.title2)} />}</h2>
                        )}
                        {editing.id === 'content2' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('content2')}>Enregistrer</button>
                            </>
                        ) : (
                            <p>{home.content2} {user && <FaEdit onClick={() => handleEdit('content2', home.content2)} />}</p>
                        )}
                    </div>
                </div>
            </section>
    
            <section className="home-section3">
                <div className="home-section3-container">
                    {editing.id === 'title3' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('title3')}>Enregistrer</button>
                        </>
                    ) : (
                        <h2>{home.title3} {user && <FaEdit onClick={() => handleEdit('title3', home.title3)} />}</h2>
                    )}
                    <div className="home-section3-services">
                        <div className="home-section3-left">
                            <div
                                className={`home-section3-service ${hoveredIndex === 0 || hoveredIndex === null ? 'home-section3-service--hovered' : ''}`}
                                onMouseEnter={() => handleMouseEnter(0)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {editing.id === 'title4' ? (
                                    <>
                                        <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSave('title4')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <h3>{home.title4} {user && <FaEdit onClick={() => handleEdit('title4', home.title4)} />}</h3>
                                )}
                                {editing.id === 'content4' ? (
                                    <>
                                        <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSave('content4')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <p>{home.content4} {user && <FaEdit onClick={() => handleEdit('content4', home.content4)} />}</p>
                                )}
                            </div>
                            <div
                                className={`home-section3-service ${hoveredIndex === 1 ? 'home-section3-service--hovered' : ''}`}
                                onMouseEnter={() => handleMouseEnter(1)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {editing.id === 'title5' ? (
                                    <>
                                        <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSave('title5')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <h3>{home.title5} {user && <FaEdit onClick={() => handleEdit('title5', home.title5)} />}</h3>
                                )}
                                {editing.id === 'content5' ? (
                                    <>
                                        <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSave('content5')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <p>{home.content5} {user && <FaEdit onClick={() => handleEdit('content5', home.content5)} />}</p>
                                )}
                            </div>
                        </div>
                        <div className="home-section3-right">
                            <div
                                className={`home-section3-service ${hoveredIndex === 2 ? 'home-section3-service--hovered' : ''}`}
                                onMouseEnter={() => handleMouseEnter(2)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {editing.id === 'title6' ? (
                                    <>
                                        <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSave('title6')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <h3>{home.title6} {user && <FaEdit onClick={() => handleEdit('title6', home.title6)} />}</h3>
                                )}
                                {editing.id === 'content6' ? (
                                    <>
                                        <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSave('content6')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <p>{home.content6} {user && <FaEdit onClick={() => handleEdit('content6', home.content6)} />}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <section className="home-section4">
                <div className="home-section4-container">
                    <div className="home-section4-image-content">
                        <img src={pourquoiNous} alt="Pourquoi nous ?" />
                    </div>
                    <div className="home-section4-text-content">
                        {editing.id === 'title7' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('title7')}>Enregistrer</button>
                            </>
                        ) : (
                            <h2>{home.title7} {user && <FaEdit onClick={() => handleEdit('title7', home.title7)} />}</h2>
                        )}
                        <ul className="home-section4-list">
                            <li>
                                {editing.id === 'strong1' ? (
                                    <>
                                        <strong>{home.strong1}</strong>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('strong1')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <strong>{home.strong1}</strong>
                                        {user && <FaEdit onClick={() => handleEdit('strong1', home.strong1)} />}
                                    </>
                                )}
                                {editing.id === 'content7' ? (
                                    <>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('content7')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {home.content7} 
                                        {user && <FaEdit onClick={() => handleEdit('content7', home.content7)} />}
                                    </>
                                )}
                            </li>
                            <li>
                                {editing.id === 'strong2' ? (
                                    <>
                                        <strong>{home.strong2}</strong>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('strong2')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <strong>{home.strong2}</strong>
                                        {user && <FaEdit onClick={() => handleEdit('strong2', home.strong2)} />}
                                    </>
                                )}
                                {editing.id === 'content8' ? (
                                    <>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('content8')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {home.content8} 
                                        {user && <FaEdit onClick={() => handleEdit('content8', home.content8)} />}
                                    </>
                                )}
                            </li>
                            <li>
                                {editing.id === 'strong3' ? (
                                    <>
                                        <strong>{home.strong3}</strong>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('strong3')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <strong>{home.strong3}</strong>
                                        {user && <FaEdit onClick={() => handleEdit('strong3', home.strong3)} />}
                                    </>
                                )}
                                {editing.id === 'content3' ? (
                                    <>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('content3')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {home.content3} 
                                        {user && <FaEdit onClick={() => handleEdit('content3', home.content3)} />}
                                    </>
                                )}
                            </li>
                            <li>
                                {editing.id === 'strong4' ? (
                                    <>
                                        <strong>{home.strong4}</strong>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('strong4')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <strong>{home.strong4}</strong>
                                        {user && <FaEdit onClick={() => handleEdit('strong4', home.strong4)} />}
                                    </>
                                )}
                                {editing.id === 'content9' ? (
                                    <>
                                        <textarea 
                                            className="home-item-edit" 
                                            value={editableText} 
                                            onChange={(e) => setEditableText(e.target.value)} 
                                        />
                                        <button 
                                            className="home-edit-button" 
                                            onClick={() => handleSave('content9')}
                                        >
                                            Enregistrer
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {home.content9} 
                                        {user && <FaEdit onClick={() => handleEdit('content9', home.content9)} />}
                                    </>
                                )}
                            </li>
                        </ul>

                    </div>
                </div>
            </section>

            <ReviewsSection />
    
            <section className="home-section6-container">
                <div className="home-section6-content">
                    {editing.id === 'title9' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('title9')}>Enregistrer</button>
                        </>
                    ) : (
                        <h2>{home.title9} {user && <FaEdit onClick={() => handleEdit('title9', home.title9)} />}</h2>
                    )}
                    {editing.id === 'title10' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('title10')}>Enregistrer</button>
                        </>
                    ) : (
                        <h3>{home.title10} {user && <FaEdit onClick={() => handleEdit('title10', home.title10)} />}</h3>
                    )}
                    <ul>
                        <li>
                            {editing.id === 'li1' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li1')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li1} 
                                    {user && <FaEdit onClick={() => handleEdit('li1', home.li1)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li2' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li2')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li2} 
                                    {user && <FaEdit onClick={() => handleEdit('li2', home.li2)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li3' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li3')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li3} 
                                    {user && <FaEdit onClick={() => handleEdit('li3', home.li3)} />}
                                </>
                            )}
                        </li>
                    </ul>

                    {editing.id === 'title11' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('title11')}>Enregistrer</button>
                        </>
                    ) : (
                        <h3>{home.title11} {user && <FaEdit onClick={() => handleEdit('title11', home.title11)} />}</h3>
                    )}
                    {editing.id === 'content10' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('content10')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{home.content10} {user && <FaEdit onClick={() => handleEdit('content10', home.content10)} />}</p>
                    )}
                    {editing.id === 'title12' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('title12')}>Enregistrer</button>
                        </>
                    ) : (
                        <h3>{home.title12} {user && <FaEdit onClick={() => handleEdit('title12', home.title12)} />}</h3>
                    )}
                    <ul>
                        <li>
                            {editing.id === 'li4' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li4')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li4} 
                                    {user && <FaEdit onClick={() => handleEdit('li4', home.li4)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li5' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li5')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li5} 
                                    {user && <FaEdit onClick={() => handleEdit('li5', home.li5)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li6' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li6')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li6} 
                                    {user && <FaEdit onClick={() => handleEdit('li6', home.li6)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li7' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li7')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li7} 
                                    {user && <FaEdit onClick={() => handleEdit('li7', home.li7)} />}
                                </>
                            )}
                        </li>
                    </ul>

                    {editing.id === 'content11' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('content11')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{home.content11} {user && <FaEdit onClick={() => handleEdit('content11', home.content11)} />}</p>
                    )}
                </div>
            </section>
    
            <section className="home-section7-container">
                <div className="home-section7-content">
                    {editing.id === 'title13' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('title13')}>Enregistrer</button>
                        </>
                    ) : (
                        <h2>{home.title13} {user && <FaEdit onClick={() => handleEdit('title13', home.title13)} />}</h2>
                    )}
                    {editing.id === 'content12' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('content12')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{home.content12} {user && <FaEdit onClick={() => handleEdit('content12', home.content12)} />}</p>
                    )}
                    <ol>
                        <li>
                            {editing.id === 'li8' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li8')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li8} 
                                    {user && <FaEdit onClick={() => handleEdit('li8', home.li8)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li9' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li9')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li9} 
                                    {user && <FaEdit onClick={() => handleEdit('li9', home.li9)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li10' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li10')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li10} 
                                    {user && <FaEdit onClick={() => handleEdit('li10', home.li10)} />}
                                </>
                            )}
                        </li>
                        <li>
                            {editing.id === 'li11' ? (
                                <>
                                    <textarea 
                                        className="home-item-edit" 
                                        value={editableText} 
                                        onChange={(e) => setEditableText(e.target.value)} 
                                    />
                                    <button 
                                        className="home-edit-button" 
                                        onClick={() => handleSave('li11')}
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            ) : (
                                <>
                                    {home.li11} 
                                    {user && <FaEdit onClick={() => handleEdit('li11', home.li11)} />}
                                </>
                            )}
                        </li>
                    </ol>

                    {editing.id === 'content13' ? (
                        <>
                            <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="home-edit-button" onClick={() => handleSave('content13')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{home.content13} {user && <FaEdit onClick={() => handleEdit('content13', home.content13)} />}</p>
                    )}
                </div>
            </section>
    
            <section className="home-section8">
                <div className="home-section8-container">
                    <div className="home-section8-image-content">
                        <img src={contreIndication} alt="Contre-indication" />
                    </div>
                    <div className="home-section8-text-content">
                        {editing.id === 'title14' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('title14')}>Enregistrer</button>
                            </>
                        ) : (
                            <h2>{home.title14} {user && <FaEdit onClick={() => handleEdit('title14', home.title14)} />}</h2>
                        )}
                        {editing.id === 'content14' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('content14')}>Enregistrer</button>
                            </>
                        ) : (
                            <p>{home.content14} {user && <FaEdit onClick={() => handleEdit('content14', home.content14)} />}</p>
                        )}
                        {editing.id === 'content15' ? (
                            <>
                                <textarea className="home-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="home-edit-button" onClick={() => handleSave('content15')}>Enregistrer</button>
                            </>
                        ) : (
                            <p>{home.content15} {user && <FaEdit onClick={() => handleEdit('content15', home.content15)} />}</p>
                        )}
                    </div>
                </div>
            </section>            
    
            <BlogSection />
        </main>
    );    
}

export default Home;
