import React, { useState } from 'react';
import './styles/FAQ.css';

import topArrow from "../media/icones/top_arrow.png";
import underArrow from "../media/icones/bottom_arrow.png";
import greenbackground from "../media/background/greenbackground.png";

import faqsData from "../data/faq.json";


const FAQComponent = () => {
    const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
    const faqs = faqsData.faq;

    const toggleQuestion = (index) => {
        setOpenQuestionIndex(openQuestionIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <img className="faq-container-green" src={greenbackground} alt="fond vert" />
            <h2>Questions<br /><span>Fréquemment Posées</span></h2>
            <div className="faq-item-container">
                {faqs.map((faq, index) => (
                    <div key={faq.id} className="faq-item">
                        <button className="faq-question" onClick={() => toggleQuestion(index)}>
                            {faq.question.split('\n').map((line, index, array) => (
                                <React.Fragment key={index}>
                                {line}
                                {index < array.length - 1 && <br />}
                                </React.Fragment>
                            ))}                
                            <span className="faq-icon">
                                {openQuestionIndex === index ? (
                                    <img src={topArrow} alt="Flèche de monté" width="36" height="36" />
                                ) : (
                                    <img src={underArrow} alt="Flèche de descente" width="36" height="36" />
                                )}
                            </span>
                        </button>
                        {openQuestionIndex === index && <p className="faq-answer">
                            {faq.answer.split('\n').map((line, index, array) => (
                                <React.Fragment key={index}>
                                {line}
                                {index < array.length - 1 && <br />}
                                </React.Fragment>
                            ))} 
                        </p>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQComponent;
