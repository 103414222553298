import React, { useState, useEffect } from 'react';
import '../styles/Musculo.css';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../../AuthContext";

import musculo1 from '../../media/background/musculo1.png'; 
import musculo2 from '../../media/background/musculo2.png'; 

const TroublesMusculosquelettique = () => {
  const [musculo, setMusculo] = useState([]);
  const [editing, setEditing] = useState({ id: null });
  const [editableText, setEditableText] = useState('');
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
      fetch('/data/traitement.json')
          .then((response) => response.json())
          .then((data) => setMusculo(data.musculo))
          .catch((error) => console.error("Erreur de chargement des données:", error));
  }, [dataUpdated]);

  const handleEdit = (id, text) => {
      setEditing({ id });
      setEditableText(text);
  };

  const handleSave = async (id) => {

      const bodyContent = {
          id: id,
          text: editableText,
      };

      try {
          const response = await fetch(`${apiUrl}/updateMusculo`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyContent),
              credentials: "include"
          });

          if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
          }

          setEditing({ id: null });

          console.log("Mise à jour réussie");
          setDataUpdated(prev => !prev);
      } catch (error) {
          console.error("Erreur lors de la mise à jour de musculo :", error);
      }
  };

  return (
    <div>
        <section className="troubles-musculosquelettique-section1">
            {editing.id === 'title' ? (
                <>
                    <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="musculo-edit-button" onClick={() => handleSave('title')}>Enregistrer</button>
                </>
            ) : (
                <h1>{musculo.title} {user && <FaEdit onClick={() => handleEdit('title', musculo.title)} />}</h1>
            )}
            {editing.id === 'content' ? (
                <>
                    <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="musculo-edit-button" onClick={() => handleSave('content')}>Enregistrer</button>
                </>
            ) : (
                <p>{musculo.content} {user && <FaEdit onClick={() => handleEdit('content', musculo.content)} />}</p>
            )}
        </section>

        <section className="troubles-musculosquelettique-section2">
          <div className="tms-section2-content">
            <img src={musculo1} alt="Zones touchées par les TMS" className="tms-image" />
            <div className="tms-section2-text">
              {editing.id === 'title2' ? (
                  <>
                      <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                      <button className="musculo-edit-button" onClick={() => handleSave('title2')}>Enregistrer</button>
                  </>
              ) : (
                  <h2>{musculo.title2} {user && <FaEdit onClick={() => handleEdit('title2', musculo.title2)} />}</h2>
              )}
              <ul>
                <li>
                  {editing.id === 'strong1' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('strong1')}>Enregistrer</button>
                      </>
                  ) : (
                      <strong>{musculo.strong1} {user && <FaEdit onClick={() => handleEdit('strong1', musculo.strong1)} />}</strong>
                  )}
                  {editing.id === 'li1' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('li1')}>Enregistrer</button>
                      </>
                  ) : (
                      <>{musculo.li1} {user && <FaEdit onClick={() => handleEdit('li1', musculo.li1)} />}</>
                  )}
                </li>
                <li>
                  {editing.id === 'strong2' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('strong2')}>Enregistrer</button>
                      </>
                  ) : (
                      <strong>{musculo.strong2} {user && <FaEdit onClick={() => handleEdit('strong2', musculo.strong2)} />}</strong>
                  )}
                  {editing.id === 'li2' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('li2')}>Enregistrer</button>
                      </>
                  ) : (
                      <>{musculo.li2} {user && <FaEdit onClick={() => handleEdit('li2', musculo.li2)} />}</>
                  )}
                </li>
                <li>
                  {editing.id === 'strong3' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('strong3')}>Enregistrer</button>
                      </>
                  ) : (
                      <strong>{musculo.strong3} {user && <FaEdit onClick={() => handleEdit('strong3', musculo.strong3)} />}</strong>
                  )}
                  {editing.id === 'li3' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('li3')}>Enregistrer</button>
                      </>
                  ) : (
                      <>{musculo.li3} {user && <FaEdit onClick={() => handleEdit('li3', musculo.li3)} />}</>
                  )}
                </li>
                <li>
                  {editing.id === 'strong4' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('strong4')}>Enregistrer</button>
                      </>
                  ) : (
                      <strong>{musculo.strong4} {user && <FaEdit onClick={() => handleEdit('strong4', musculo.strong4)} />}</strong>
                  )}
                  {editing.id === 'li4' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('li4')}>Enregistrer</button>
                      </>
                  ) : (
                      <>{musculo.li4} {user && <FaEdit onClick={() => handleEdit('li4', musculo.li4)} />}</>
                  )}
                </li>
                <li>
                  {editing.id === 'strong5' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('strong5')}>Enregistrer</button>
                      </>
                  ) : (
                      <strong>{musculo.strong5} {user && <FaEdit onClick={() => handleEdit('strong5', musculo.strong5)} />}</strong>
                  )}
                  {editing.id === 'li5' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('li5')}>Enregistrer</button>
                      </>
                  ) : (
                      <>{musculo.li5} {user && <FaEdit onClick={() => handleEdit('li5', musculo.li5)} />}</>
                  )}
                </li>
                <li>
                  {editing.id === 'strong6' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('strong6')}>Enregistrer</button>
                      </>
                  ) : (
                      <strong>{musculo.strong6} {user && <FaEdit onClick={() => handleEdit('strong6', musculo.strong6)} />}</strong>
                  )}
                  {editing.id === 'li6' ? (
                      <>
                          <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                          <button className="musculo-edit-button" onClick={() => handleSave('li6')}>Enregistrer</button>
                      </>
                  ) : (
                      <>{musculo.li6} {user && <FaEdit onClick={() => handleEdit('li6', musculo.li6)} />}</>
                  )}
                </li>
              </ul>
            </div>
          </div>
      </section>

      <div className="tms-button-container">
        <a 
              href="https://prendre-mon-rdv.com/herrmann-benedicte-hamaissi-clelia-beccaria-theo/etiopathe/chevreuse" 
              target="_blank" 
              rel="noopener noreferrer"
          >
              <button className="tms-button">Prendre mon Rendez-vous</button>
          </a>
      </div>

      <section className="troubles-musculosquelettique-section3">
          {editing.id === 'title3' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('title3')}>Enregistrer</button>
              </>
          ) : (
              <h2>{musculo.title3} {user && <FaEdit onClick={() => handleEdit('title3', musculo.title3)} />}</h2>
          )}
          {editing.id === 'content2' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('content2')}>Enregistrer</button>
              </>
          ) : (
              <p>{musculo.content2} {user && <FaEdit onClick={() => handleEdit('content2', musculo.content2)} />}</p>
          )}
          {editing.id === 'title4' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('title4')}>Enregistrer</button>
              </>
          ) : (
              <h2>{musculo.title4} {user && <FaEdit onClick={() => handleEdit('title4', musculo.title4)} />}</h2>
          )}
          {editing.id === 'content3' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('content3')}>Enregistrer</button>
              </>
          ) : (
              <p>{musculo.content3} {user && <FaEdit onClick={() => handleEdit('content3', musculo.content3)} />}</p>
          )}
      </section>

      <img src={musculo2} alt="douleur main" className="troubles-musculosquelettique-background"/>

      <section className="troubles-musculosquelettique-section4">
          {editing.id === 'title5' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('title5')}>Enregistrer</button>
              </>
          ) : (
              <h2>{musculo.title5} {user && <FaEdit onClick={() => handleEdit('title5', musculo.title5)} />}</h2>
          )}
          {editing.id === 'content4' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('content4')}>Enregistrer</button>
              </>
          ) : (
              <p>{musculo.content4} {user && <FaEdit onClick={() => handleEdit('content4', musculo.content4)} />}</p>
          )}
          {editing.id === 'content5' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('content5')}>Enregistrer</button>
              </>
          ) : (
              <p>{musculo.content5} {user && <FaEdit onClick={() => handleEdit('content5', musculo.content5)} />}</p>
          )}
          {editing.id === 'title6' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('title6')}>Enregistrer</button>
              </>
          ) : (
              <h2>{musculo.title6} {user && <FaEdit onClick={() => handleEdit('title6', musculo.title6)} />}</h2>
          )}
          {editing.id === 'content6' ? (
              <>
                  <textarea className="musculo-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                  <button className="musculo-edit-button" onClick={() => handleSave('content6')}>Enregistrer</button>
              </>
          ) : (
              <p>{musculo.content6} {user && <FaEdit onClick={() => handleEdit('content6', musculo.content6)} />}</p>
          )}
      </section>
    </div>
  );
};

export default TroublesMusculosquelettique;
